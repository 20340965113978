import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Faq from '../../components/Faq';
import Layout from '../../components/Layout';

// import useLocale from '../../hooks/useLocale';
// import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import imgPhoto1 from '../../assets/images/about-tefl/Photo 1 (1).png';
import imgPhoto2 from '../../assets/images/about-tefl/Photo 2 (1).png';
import imgPhoto3 from '../../assets/images/about-tefl/Photo 3 (1).png';

import '../../assets/styles/pages/about-tefl-page.scss';
import { spanishToEnglishUrls } from '../../constants/es-en-urls-maps';

const faq1Title = 'Why Study TEFL?';
const faq1Text =
  'You are instantly employable all over the world. There is more demand for English language than ever before, due to its global dominance as a lingua franca, and the TEFL job market is booming – teachers can find work all over the world, from China to South America, from Russia to Sri Lanka. And with people moving around more and more, there is also demand for ESL teachers in countries where English is the official language and new arrivals want to learn English.';

const faq2Title = 'How do I become a TEFL teacher?';
const faq2Text = `
1. Complete an accredited TEFL course, such as the Trinity CertTESOL.<br/>
2. If you study at a good school, they will put you in touch with local schools who are looking for teachers.<br/>
3. Complete your CV, contact schools and apply for jobs!
`;

const faq3Title = 'Where should I do a TEFL course?';
const faq3Text =
  'You can do a TEFL course in your home country, but we highly recommend doing a course overseas, in your chosen location. You’ll make friends, get an idea of the job market and benefit from the local experience of the tutors. You’ll get to be a student for the first few weeks overseas, instead of having to start job hunting as soon as you arrive.';

const faq4Title = 'What makes it official?';
const faq4Text =
  'The Trinity CertTESOL is regulated by Ofqual at Level 5. Ofqual is the exam “watchdog” and is the government department that regulates UK qualifications and exams. Level 5 on the Qualifications and Credit Framework (QCF) is regarded as comparable in difficulty to the second year of an undergraduate degree.';

const faq5Title = 'What is the difference between these qualifications and other certificates?';
const faq5Text = `
Other courses and certificates have varying levels of accreditation, 
varying from no accreditation to some course content but no moderation visits. They may be accredited by independent companies but are not government regulated. Some courses are also regulated by Ofqual but at a lower level (e.g. weekend TEFL courses) 
which are not as rigorous and don’t provide real teaching practice.
</br></br>At TEFL Iberia, in order to be able to provide the Trinity CertTESOL qualification, all the course content is strictly moderated, the staff are vetted, the school facilities are inspected and an external moderator comes to every single course to make sure the standards are kept high.
`;

const faq6Title = 'Why did TEFL Iberia choose to become accredited by Trinity College London?';
const faq6Text =
  'The Trinity CertTESOL course includes input sessions on teaching children as well as adults, something we feel is important as there is a big demand in Spain for TEFL teachers who work with young people. Trinity is also more flexible than the Cambridge CELTA, offering course providers like ourselves more scope to adapt and interpret the course according to our standards. As we are one of the top-rated independent TEFL courses worldwide, we wanted to retain the aspects of the course which set us above other TEFL courses.';

// Es version

const faq1TitleEs = '¿Por qué estudiar TEFL?';
const faq1TextEs =
  'Eres empleable en todo el mundo al momento de terminar tu curso. Actualmente, existe una inmensa demanda del idioma inglés debido a su dominio global como lingua franca. Como profesor de TEFL puedes encontrar trabajo en todo el mundo, desde China hasta Sur América, desde Rusia hasta Sri Lanka. Como las personas se desplazan cada vez más, también hay demanda de profesores de inglés como segunda lengua en países donde el inglés es el idioma oficial y los recién llegados necesitan aprender inglés.';

const faq2TitleEs = '¿Cómo puedo convertirme en un profesor/a de TEFL?';
const faq2TextEs = `
  1. Completa un curso acreditado de TEFL, como el Trinity CertTESOL.<br/>
  2. Si estudias en una buena escuela, te pondrán en contacto con escuelas locales que están buscando profesores.<br/>
  3. ¡Completa tu currículum vitae, ponte en contacto con escuelas y aplica para la posición que más te llame la atención!
  `;

const faq3TitleEs = '¿Dónde debería tomar un curso TEFL? ';
const faq3TextEs =
  'Puedes hacer un curso TEFL en tu país de origen, pero siempre recomendamos hacerlo en el extranjero, en la ubicación donde más te apetezca. Harás amigos, obtendrás una idea del mercado laboral y te beneficiarás de la experiencia local de los profesores. Tendrás la oportunidad de ser estudiante durante las primeras semanas en el extranjero, en lugar de tener que comenzar la búsqueda de empleo tan pronto llegues.';

const faq4TitleEs = '¿Qué lo hace oficial?';
const faq4TextEs =
  'El Trinity CertTESOL está regulado por Ofqual nivel 5. Ofqual es el organismo regulador de exámenes y calificaciones en el Reino Unido. El nivel 5 en el Marco de Cualificaciones y Créditos (QCF, por sus siglas en inglés) se considera equivalente en dificultad al segundo año de una licenciatura universitaria.';

const faq5TitleEs = '¿Cuál es la diferencia entre estas calificaciones y otros certificados?';
const faq5TextEs = `
Otros cursos y certificados tienen diferentes niveles de acreditación, que van desde ninguna acreditación hasta cierto contenido del curso pero sin visitas de moderación. Pueden estar acreditados por empresas independientes pero no están regulados por el gobierno. Algunos cursos también están regulados por Ofqual, pero a un nivel inferior (por ejemplo, cursos TEFL de fin de semana) que no son tan rigurosos y no brindan una práctica real de enseñanza.
  </br></br>En TEFL Iberia, para poder ofrecer la calificación Trinity CertTESOL, todo el contenido del curso se supervisa estrictamente, se realiza una selección exhaustiva del personal, se inspeccionan las instalaciones de la escuela y un moderador externo asiste a cada curso para garantizar que se mantengan altos estándares.
  `;

const faq6TitleEs = '¿Por qué TEFL Iberia decidió ser acreditado por Trinity College London?';
const faq6TextEs =
  'El curso Trinity CertTESOL incluye sesiones sobre la enseñanza a niños y adultos, algo que consideramos importante ya que hay una gran demanda en España de profesores de TEFL que trabajan con jóvenes. Trinity también ofrece más flexibilidad que el Cambridge CELTA, lo que permite a proveedores de cursos como nosotros adaptar e interpretar el curso según nuestros estándares. Como somos uno de los cursos de TEFL independientes mejor valorados en todo el mundo, queríamos mantener los aspectos del curso que nos diferencian de otros cursos de TEFL.';

const AboutTefl = () => {
  // const { enLocale } = useLocale({ matchPathName: '/es/' });
  const [enLocale] = useState(
    () =>
      typeof window === 'object' &&
      !spanishToEnglishUrls.has(window.location.pathname.replace('/es', '').slice(0, -1))
  );

  // useEffect(() => {
  //   setEnLocale(
  //     !spanishToEnglishUrls.has(window.location.pathname.replace('/es', '').slice(0, -1))
  //   );
  // }, []);

  return (
    <Layout
      title={{ id: 'whatIsTefs.seo.title' }}
      description={{ id: 'whatIsTefs.seo.description' }}
    >
      <div className="about-tefl-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="whatIsTefs.sec1.title" />
            </h1>
          </div>
        </section>
        <section className="second-section c-section-py c-faqs-section">
          <div className="container">
            <div className="row top">
              <div className="col-lg-6 col-md-7 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="whatIsTefs.sec2.title" />
                  </h2>
                  <p className="block_l__description">
                    <FormattedMessage id="whatIsTefs.sec2.description" />
                  </p>
                  <div className="faq-wrapper">
                    <Faq
                      title={enLocale ? faq1Title : faq1TitleEs}
                      description={enLocale ? faq1Text : faq1TextEs}
                    />
                    <Faq
                      title={enLocale ? faq2Title : faq2TitleEs}
                      description={enLocale ? faq2Text : faq2TextEs}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-12">
                <div className="block_r">
                  <img src={imgPhoto1} alt="imgPhoto1" className="block_r__img--l" />
                  <img src={imgPhoto2} alt="imgPhoto2" className="block_r__img--r" />
                </div>
              </div>
            </div>
            <div className="row bottom">
              <div className="col-lg-6 col-md-5 col-12">
                <div className="block_l">
                  <img src={imgPhoto3} alt="imgPhoto3" className="block_l__img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-12">
                <div className="block_r">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="whatIsTefs.sec3.title" />
                  </h2>
                  <p className="block_l__description">
                    <FormattedMessage id="whatIsTefs.sec3.description" />
                  </p>
                  <div className="faq-wrapper">
                    <Faq
                      title={enLocale ? faq3Title : faq3TitleEs}
                      description={enLocale ? faq3Text : faq3TextEs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="third-section c-faqs-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="block_l">
                  <h2 className="block_l__title c-title-34">
                    <FormattedMessage id="whatIsTefs.sec4.title" />
                  </h2>
                  <p className="block_l__description">
                    <FormattedMessage id="whatIsTefs.sec4.description" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="block_r">
                  <Faq
                    title={enLocale ? faq4Title : faq4TitleEs}
                    description={enLocale ? faq4Text : faq4TextEs}
                  />
                  <Faq
                    title={enLocale ? faq5Title : faq5TitleEs}
                    description={enLocale ? faq5Text : faq5TextEs}
                  />
                  <Faq
                    title={enLocale ? faq6Title : faq6TitleEs}
                    description={enLocale ? faq6Text : faq6TextEs}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutTefl;
